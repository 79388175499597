body {
  margin: 0;
  font-casual: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(242, 242, 242) !important;
}

body * {
  overflow: visible;
}

code {
  font-casual: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Datatables styling overrides */
.MuiTableCell-body,
.MuiTableCell-head {
  word-wrap: break-word;
  white-space: nowrap;
}

.MuiFormControlLabel-labelPlacementEnd {
  margin-left: 0 !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm,
.MuiDialogActions-root {
  background-color: #f6f5f5 !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation2.MuiPopover-paper {
  max-width: 50%;
}

.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedStart.MuiInputBase-inputAdornedEnd {
  cursor: pointer;
}

.MuiFileInput-placeholder {
  cursor: pointer;
}

.adminpanel-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.adminpanel-button:hover {
  background-color: #e0e0e0;
}

.adminpanel-button:active {
  background-color: #d0d0d0;
}

.balance-card {
  font-size: 0.85rem !important;
}