.ps-menu-root {
  font-size: 0.97rem !important;
}

.ps-menu-button {
  height: initial !important;
  padding-top: 0.7rem !important;
  padding-bottom: 0.7rem !important;
  font-weight: 500;
  padding-right: 1.7rem !important;
}

.ps-menu-button:hover {
  background-color: rgb(180, 190, 200) !important;
  border-color: rgb(180, 190, 200) !important;
  color: rgb(31, 41, 55) !important;
}

.ps-menu-button.active {
  background-color: rgb(180, 190, 200);
  border-color: rgb(66, 75, 87);
  color: rgb(31, 41, 55);
  border: 0 solid #e2e8f0;
  box-sizing: border-box;
}

.ps-sidebar-container {
  background-color: rgb(242, 242, 242) !important;
}

.ps-submenu-content {
  background-color: rgb(242, 242, 242) !important;
}

.ps-menu-label {
  white-space: unset !important;
}

.sidebar-wrapper {
  position: fixed;
  height: 100vh;
  top: 0px;
  background-color: #f2f2f2;
  padding-top: 4.5rem;
  overflow-y: auto;
}
