#holidays_main {
  padding: 20px;
}

#holidays_main * {
  overflow: visible;
}

.month-container {
  margin-top: 20px;
}

.remove_bg_color * {
  background-color: transparent !important;
}
