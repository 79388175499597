#vacations_main {
  padding: 20px;
}

#vacations_main * {
  overflow: visible;
}

.month-container {
  margin-top: 20px;
}
